<template>
    <div id="general-info-form">
        <div class="md-layout md-alignment-top-space-between">
            <div class="md-layout-item md-size-100" id="top-info" v-if="!showAppt">
                <p>
                    <strong>Please note</strong>: Reappointment is not available for new candidates or candidates imported from Faculty Search. If you would like to reappoint a candidate, please select them using Option #2 in the previous step.
                </p>
            </div>
            <md-field class="md-layout-item md-size-45">
                <label for="admin-org">Administrating Org</label>
                <md-select name="admin-org" id="admin-org" v-model='adminOrg' v-on:input="updateDivision" required>
                    <md-option v-for='unit in units' v-bind:key='unit.id' v-bind:value='unit.id'>{{ unit.name }}</md-option>
                </md-select>
            </md-field>
            <md-field v-if='showDivs' class="md-layout-item md-size-45">
                <label for="division">Division</label>
                <md-select name="division" id="division" v-model='division' required>
                    <md-option v-for='div in divisions' v-bind:key='div' v-bind:value='div'>{{ div }}</md-option>
                </md-select>
            </md-field>
            <md-field class="md-layout-item md-size-100 spacer spacer-no-bottom-margin"></md-field>
            <md-field class="md-layout-item md-size-45" v-if="showAppt">
                <label for="action">Appointment Type</label>
                <md-select name="action" id="case-action" v-model='caseAction' @md-selected="clearTitle" required>
                    <md-option v-for='action in actions' v-bind:key="action" v-bind:value="action">{{ action }}</md-option>
                </md-select>
            </md-field>
            <md-field class="md-layout-item md-size-45">
                <label for="title">Proposed Title</label>
                <md-select name="title" id="title" v-model='title' v-on:input="titleUpdated" required>
                    <md-option v-for='title in titles' v-bind:key='title' v-bind:value='title'>{{ title }}</md-option>
                </md-select>
                <span class="md-helper-text" v-if="showAppt">Select an appointment type to see title options. For graduate students, if unsure of title contact your Business Office.
                </span>
            </md-field>
            <md-field class="md-layout-item md-size-100 spacer spacer-no-bottom-margin" v-if="hasPositionHistory"></md-field>
            <div class="md-layout-item md-size-45" v-if="hasPositionHistory">
            </div>
            <div class="md-layout-item md-size-45">
                <p class="md-helper-text" v-if="hasPositionHistory">Past Academic Appointments (from Workday):</p>
                <ul>
                  <li v-for="(position,key) in positionHistory" :key="key">
                    {{ position.jobProfileName }} - {{ position.occupantFromYear}}
                  </li>
                </ul>
            </div>
            <md-field class="md-layout-item md-size-100 spacer spacer-no-bottom-margin"></md-field>
            <md-field class="md-layout-item md-size-45" v-if="positionSpanEntryType == 'term'">
                <label for="start-term">Start Term</label>
                <md-select name="start-term" id="start-term" v-model='startTerm' v-on:input="clearEndTerm" required>
                    <md-option v-for='(startYearText, startYearKey) in allTerms' v-bind:key='startYearKey' v-bind:value='startYearKey'>{{ startYearText }}</md-option>
                </md-select>
            </md-field>
            <md-field class="md-layout-item md-size-45" v-if="positionSpanEntryType == 'term'">
                <label for="end-term">End Term</label>
                <md-select name="end-term" id="end-term" v-model='endTerm'>
                    <md-option v-if="omitEndTermAllowed">--None--</md-option>
                    <md-option v-for='(endYearText, endYearKey) in endYears' v-bind:key='endYearKey' v-bind:value='endYearKey'>{{ endYearText }}</md-option>
                </md-select>
            </md-field>
            <md-datepicker md-immediately class="md-layout-item md-size-45" v-if="positionSpanEntryType == 'date' || positionSpanEntryType == 'start_date'" v-model="startDate" required>
                <label>Start Date</label>
            </md-datepicker>
            <md-datepicker md-immediately class="md-layout-item md-size-45" v-if="positionSpanEntryType == 'date'" v-model="endDate" required>
                <label>End Date</label>
            </md-datepicker>
            <md-datepicker md-immediately class="md-layout-item md-size-45" v-if="rankStartDateAllowed" v-model="rankStartDate">
                <label>Rank Start Date</label>
            </md-datepicker>
            <md-field v-if="rankStartDateAllowed" class="md-layout-item md-size-100 spacer spacer-no-bottom-margin"></md-field>
            <md-checkbox v-model="lps" v-if="lpsUser == 'all' && lpsAllowed" class="md-layout-item md-size-100">This is an LPS Appointment</md-checkbox>
            <md-checkbox v-model="langInstructor" v-if="languageInstructionAllowed" class="md-layout-item md-size-50">This is a Language Instructor Appointment</md-checkbox>
            <md-checkbox v-model="hasFunding" v-if="omitFundingAllowed" class="md-layout-item md-size-50">This position includes funding</md-checkbox>
            <md-checkbox v-model="hasCourses" v-if="omitCoursesAllowed" class="md-layout-item md-size-60">This position includes courses</md-checkbox>
            <md-checkbox v-model="visaRequired" v-if="visaInfoAllowed" class="md-layout-item md-size-45">This position requires a visa</md-checkbox>
            <md-field v-if="visaRequired" class="md-layout-item md-size-45">
                <label for="proposedSal">Visa Type</label>
                <md-input v-model="visaType"></md-input>
            </md-field>
        </div>
        <md-field v-if="showCitizenship" class="md-layout-item md-size-45">
            <label for="citizenship">U.S. Citizenship Status</label>
            <md-select name="citizenship" v-model='citizenship' required>
                <md-option v-for='(citizenshipOpt, index) in citizenshipOptions' v-bind:key='index' v-bind:value='citizenshipOpt'>{{ citizenshipOpt }}</md-option>
            </md-select>
        </md-field>
        <div class="md-layout md-alignment-top-space-between" v-if="showDegreeCompletion">
            <md-checkbox v-model="degreeCompleted" class="md-layout-item md-size-50">The candidate has received their Ph.D. (or equivalent degree)</md-checkbox>
        </div>
        <div class="md-layout md-alignment-top-space-between" v-if="showDegreeCompletion">
            <md-datepicker md-immediately class="md-layout-item md-size-45" v-if="showdegreeCompletedDate" v-model="degreeCompletedDate">
                <label>Date of anticipated degree completion</label>
            </md-datepicker>
        </div>
        <div class="md-layout md-alignment-top-space-between" v-if="showReviewCmteSelect">
            <p>Select which academic ranks to include on the Department Faculty Review Committee. If you will not be using Interfolio for the Department Faculty Review uncheck all options.</p>
            <md-checkbox v-model="includeAssist" class="md-layout-item md-size-50">Assistant Professors</md-checkbox>
            <md-checkbox v-model="includeAssoc" class="md-layout-item md-size-50">Associate Professors</md-checkbox>
            <md-checkbox v-model="includeFull" class="md-layout-item md-size-50">Full Professors</md-checkbox>
        </div>
        <div class="md-layout md-alignment-top-space-between">
            <p>Include any additional information that explains the reason for or function of this position including, if appropriate, the following:
                <ul>
                    <li>whom the position may replace</li>
                    <li>what need the position fulfills within the department/center</li>
                    <li>any other information that would be necessary for all reviewers to make an informed decision about this position request</li>
                </ul>
            </p>
            <md-field class="md-layout-item md-size-90">
                <label for="more-info">Additional Position Information</label>
                <md-textarea name="more-info" id="more-info" v-model='positionDesc' :required="requireAddInfo"></md-textarea>
            </md-field>
        </div>
        <div class="md-layout md-alignment-top-center">
            <md-button class="md-raised" @click="$emit('setDone', 'second,first')">Back</md-button>
            <md-button class="md-raised md-primary" @click="validate"><span v-if="returnToConfirm">Return to Confirmation</span><span v-else>Continue</span></md-button>
            <!-- <md-button class="md-raised md-primary" @click="skip">Skip</md-button> -->
        </div>
        <FormInvalidWarning v-bind:showInvalidDialog='showInvalidDialog' v-bind:formErrorMessage='formErrorMessage' v-on:closeWarning='showInvalidDialog = false'/>
        <div id="reapp-chek">
            <md-dialog :md-active.sync="showReappCheckDialog">
                <md-dialog-title>Validating Reappointment</md-dialog-title>
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item md-size-90">
                        <p>
                            {{ reappErrorMessage }}
                        </p>
                    </div>
                </div>
                <md-dialog-actions>
                    <md-button class="md-raised md-primary" @click="showReappCheckDialog = false">Edit</md-button>
                    <md-button class="md-raised md-accent" @click="overrideValidation">Override Validation</md-button>
                </md-dialog-actions>
            </md-dialog>
        </div>
    </div>
</template>

<script>
    import FormInvalidWarning from '@/components/FormInvalidWarning.vue'

    export default {
        name: 'GeneralInfo',
        components: {
            FormInvalidWarning
        },
        props: {
            allTerms: Object,
            lpsUser: String,
            returnToConfirm: Boolean,
            initFromStore: Boolean
        },
        created() {
            this.$store.dispatch('sasData/loadUnitOrgDivisionMap');
            this.startTerm = this.defaultStartYear;
            this.endTerm = this.defaultStartYear;
            this.getPositionHistory();
            this.$material.locale.dateFormat =  'MM/dd/yyyy';
            if(this.initFromStore) {
                let storeData = this.$store.getters['form/stepData']('positionStep');
                for(let field in storeData) {
                  if(field !== 'step') {
                    this[field] = storeData[field];
                  }
                }
            }
        },
        watch: {
            candidatePennId() {
                this.getPositionHistory();
            }
        },
        data: function() {
            return {
                adminOrg: this.$store.getters['form/stepData']('positionStep')['adminOrg'] || null,
                division: this.$store.getters['form/stepData']('positionStep')['division'] || null,
                startTerm: this.$store.getters['form/stepData']('positionStep')['startTerm'] || null,
                endTerm: this.$store.getters['form/stepData']('positionStep')['endTerm'] || null,
                startDate: this.$store.getters['form/stepData']('positionStep')['startDate'] || "",
                endDate: this.$store.getters['form/stepData']('positionStep')['endDate'] || "",
                caseAction: this.$store.getters['form/stepData']('positionStep')['caseAction'] || null,
                title: this.$store.getters['form/stepData']('positionStep')['title'] || null,
                positionDesc: this.$store.getters['form/stepData']('positionStep')['positionDesc'] || '',
                lps: this.$store.getters['form/stepData']('positionStep')['lps'] || false,
                langInstructor: this.$store.getters['form/stepData']('positionStep')['langInstructor'] || false,
                hasFunding: typeof this.$store.getters['form/stepData']('positionStep')['hasFunding'] != "undefined" ? this.$store.getters['form/stepData']('positionStep')['hasFunding'] : true,
                hasCourses: typeof this.$store.getters['form/stepData']('positionStep')['hasCourses'] != "undefined" ? this.$store.getters['form/stepData']('positionStep')['hasCourses'] : true,
                visaRequired: this.$store.getters['form/stepData']('positionStep')['visaRequired'] || false,
                visaType: this.$store.getters['form/stepData']('positionStep')['visaType'] || "",
                includeAssist: this.cmteInclude('ASST'),
                includeAssoc: this.cmteInclude('ASSO'),
                includeFull: this.cmteInclude('PROF'),
                facultyCommitteeRankCodes: this.$store.getters['form/stepData']('positionStep')['facultyCommitteeRankCodes'] || '',
                rankStartDate: this.$store.getters['form/stepData']('positionStep')['rankStartDate'] || "",
                showInvalidDialog: false,
                formErrorMessage: '',
                partTime: [
                    "Lecturer (Part Time)",
                    "Graduate Student Instructor"
                ],
                reappErrorMessage: '',
                showReappCheckDialog: false,
                overrideReapp: false,
                candidatePennId: this.$store.getters['form/stepData']('candidateStep')['candidatePennId'] || '',
                degreeCompleted: typeof this.$store.getters['form/stepData']('positionStep')['degreeCompleted'] != "undefined" ? this.$store.getters['form/stepData']('positionStep')['degreeCompleted'] : true,
                degreeCompletedDate: this.$store.getters['form/stepData']('positionStep')['degreeCompletedDate'] || "",
                citizenship: this.$store.getters['form/stepData']('positionStep')['citizenship'] || null,
                citizenshipOptions: ["U.S. Citizen", "Permanent Resident", "Foreign National"]
            }
        },
        computed: {
            units() {
                let units = this.$store.getters['userUnits'];
                let mappedUnits = this.$store.getters['sasData/restictInterfolioUnitsToMappedUnits'](units);
                return mappedUnits;
            },
            divisions() {
                if (this.adminOrg) {
                    return this.$store.getters['sasData/getUnitDivisions'](this.adminOrg);
                }
                return [];
            },
            positionHistory() {
                return this.$store.getters['sasData/positionHistory'];
            },
            showDivs() {
                var divs = this.divisions;
                if (divs.length > 1) {
                    return true;
                }
                return false;
            },
            showAppt() {
                if (!this.$store.getters['form/stepData']('candidateStep')['candidate']) {
                    return false;
                }
                return true;
            },
            actions() {
                return this.$store.getters['title/actionTypes']
            },
            titleActions() {
                return this.$store.getters['title/titleActions']
            },
            titles() {
                var titleOptions = [];
                if (this.caseAction || !this.showAppt) {
                    var selectedAction = 'Appointment';
                    if (this.caseAction) {
                        selectedAction = this.caseAction
                    }
                    var allActions = this.titleActions.filter(item => item.actionType == selectedAction);
                    for (let titleAction of allActions) {
                        var fullTitle = titleAction.title
                        if (titleAction.specialCase) {
                            fullTitle += " - " + titleAction.specialCase;
                        }
                        if(titleOptions.indexOf(fullTitle == -1)) {
                            titleOptions.push(fullTitle);
                        }
                    }
                }
                return titleOptions;
            },
            hideFunding() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.hideFunding;
                }
                return false;
            },
            omitFundingAllowed() {
                if (this.hideFunding) {
                    return false;
                }
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canOmitFunding;
                }
                return false;
            },
            hideCourses() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.hideCourses;
                }
                return false;
            },
            lpsAllowed() {
              if (this.title) {
                var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                return selectedTitle.canEnterLPS;
              }
              return true;
            },
            languageInstructionAllowed() {
              if (this.title) {
                var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                return selectedTitle.canEnterLanguageInstruction;
              }
              return true;
            },
            omitCoursesAllowed() {
                if (this.hideCourses) {
                    return false;
                }
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canOmitCourses;
                }
                return false;
            },
            visaInfoAllowed() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterVisaInfo;
                }
                return false;
            },
            rankStartDateAllowed() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterRankStartDate;
                }
                return false;
            },
            positionSpanEntryType() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.positionSpanEntryType;
                }
                return "term";
            },
            omitEndTermAllowed() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canOmitEndTerm;
                }
                return false;
            },
            endTermRequired() {
                return (this.positionSpanEntryType == "term" && !this.omitEndTermAllowed);
            },
            startYears() {
                return this.allTerms;
            },
            showReviewCmteSelect() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canCreateFacultyCommittee;
                }
                return false;
            },
            showCitizenship() {
              if (this.title) {
                var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                return selectedTitle.canEnterCitizenship;
              }
              return false;
            },
            showDegreeCompletion() {
              if (this.title) {
                var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                return selectedTitle.canEnterDegreeCompletion;
              }
              return false;
            },
            showdegreeCompletedDate() {
                if (this.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterDegreeCompletion && !this.degreeCompleted;
                }
                return false;
            },
            defaultStartYear() {
                    var current_year = parseInt(new Date().getFullYear());
                    var current_month = new Date().getMonth();
                    if (current_month >= 1 && current_month <= 8) {
                        return current_year + "_FALL";
                    } else {
                        return current_year + "_SPRING";
                    }
                },
                endYears() {
                    var endYears = this.allTerms;
                    var termValues = {
                        "_SPRING": {"_SPRING": " Spring", "_SUMMER_11_WEEK": " Summer 11 Week", "_SUMMER_1": " Summer 1", "_SUMMER_2": " Summer 2", "_FALL": " Fall"},
                        "_SUMMER_11_WEEK": {"_SUMMER_11_WEEK": " Summer 11 Week", "_FALL": " Fall"},
                        "_SUMMER_1": {"_SUMMER_1": " Summer 1", "_SUMMER_2": " Summer 2", "_FALL": " Fall"},
                        "_SUMMER_2": {"_SUMMER_2": " Summer 2", "_FALL": " Fall"},
                        "_FALL": {"_FALL": " Fall"}
                    };
                    if (this.startTerm) {
                        endYears = {};
                        var current_year = parseInt(new Date().getFullYear());
                        var selectedYear = parseInt(this.startTerm.slice(0, 4));
                        var selectedTerm = this.startTerm.slice(4);
                        for (var i = selectedYear; i < current_year + 8; i++) {
                            var endTerms = termValues["_SPRING"];
                            if (i == selectedYear) {
                                endTerms = termValues[selectedTerm];
                            }
                            for(var termKey in endTerms) {
                                endYears[i + termKey] = i + endTerms[termKey];
                            }
                        }
                    }
                    return endYears;
                },
                requireAddInfo() {
                    if (this.lpsUser == 'lpsOnly' || this.lps) {
                        return false;
                    }
                    return true;
                },
                hasPositionHistory() {
                    if (this.positionHistory && this.positionHistory.length > 0) {
                        return true;
                    }
                    return false;
                },
                nextStep() {
                    if (this.hideFunding) {
                        if (this.hideCourses) {
                            return 'fifth';
                        }
                        return 'fourth';
                    }
                    if (!this.hasFunding && this.omitFundingAllowed) {
                        if (!this.hasCourses && this.omitCoursesAllowed) {
                            return 'fifth';
                        }
                        return 'fourth';
                    }
                    return 'third';
                }
        },
        methods: {
            clearEndTerm: function () {
                if (this.endTermRequired) {
                    this.endTerm = this.startTerm;
                } else {
                    this.endTerm = "";
                }
            },
            clearEndDate: function () {
                this.endDate = "";
            },
            clearTitle: function () {
                this.title = null;
            },
            cmteInclude(rank){
                if (this.facultyCommitteeRankCodes) {
                    return this.facultyCommitteeRankCodes.includes(rank);
                }
                return true;
            },
            getPositionHistory: function () {
                if(this.candidatePennId) {
                    this.$store.dispatch('sasData/loadPositionHistory', {workerPennId: this.candidatePennId});
                }
            },
            updateDivision: function () {
                this.division = null;
            },
            updateVisa: function () {
                if (this.visaRequired && !this.visaInfoAllowed) {
                    this.visaRequired = false;
                }
                this.startTerm = this.endTerm = "";
                this.startDate = "";
                this.endDate = "";
            },
            skip: function() {
                // TODO: delete, created for demos and testing
                this.saveStep();
                this.$emit('setDone', 'second,' + this.nextStep);
            },
            matchFullTitle: function(titleItem) {
                if (titleItem.title == this.title && !titleItem.specialCase) return true;
                if (titleItem.title + " - " + titleItem.specialCase == this.title) return true;
            },
            checkLanguages: function() {
                if (this.title && this.title.indexOf("Foreign") != -1) {
                    this.langInstructor = true;
                } else {
                    // this is to reset it in case they change their selection to something else
                    this.langInstructor = false;
                }
            },
            overrideValidation: function () {
                this.showReappCheckDialog = false;
                this.overrideReapp = true;
                this.saveStep();
                this.$emit('setDone', 'second,' + this.nextStep);
            },
            validate: function () {
                if (!this.showAppt) {
                    this.caseAction = "Appointment";
                }
                // check that if they unchecked funding but then changed title, funding is rechecked when appropriate
                if (!this.hasFunding && !this.omitFundingAllowed) {
                    this.hasFunding = true;
                }
                // check that if they unchecked courses but then changed title, courses are rechecked when appropriate
                if (!this.hasCourses && !this.omitCoursesAllowed) {
                    this.hasCourses = true;
                }
                if (this.visaRequired) {
                    if (this.visaInfoAllowed) {
                        if (this.visaType == "") {
                            this.formErrorMessage =  "Please provide a visa type.";
                            this.showInvalidDialog = true;
                        }
                    } else {
                        this.visaRequired = false;
                        this.visaType = "";
                    }
                }
                if (this.rankStartDate && !this.rankStartDateAllowed) {
                    this.rankStartDate = "";
                }
                this.formErrorMessage = "";
                if (!this.adminOrg) {
                    this.formErrorMessage +=  "Please select an Administrating Org.\n";
                    this.showInvalidDialog = true;
                }
                if (!this.caseAction) {
                    this.formErrorMessage  += "Please select an appointment type.\n";
                    this.showInvalidDialog = true;
                }
                if (!this.title) {
                    this.formErrorMessage += "Please select a title.\n";
                    this.showInvalidDialog = true;
                }
                if (!this.startTerm && this.positionSpanEntryType == "term") {
                    this.formErrorMessage  +=  "Please select a Start Term.\n";
                    this.showInvalidDialog = true;
                }
                if (!this.endTerm && this.positionSpanEntryType == "term" && !this.omitEndTermAllowed) {
                    this.formErrorMessage  += "Please select an End Term.\n";
                    this.showInvalidDialog = true;
                }
                if (!this.startDate && this.positionSpanEntryType == "date") {
                    this.formErrorMessage +=  "Please select a Start Date.\n";
                    this.showInvalidDialog = true;
                }
                if (!this.endDate && this.positionSpanEntryType == "date") {
                    this.formErrorMessage += "Please select an End Date.\n";
                    this.showInvalidDialog = true;
                }
                if (this.showCitizenship && this.citizenship === null) {
                    this.formErrorMessage += "Please enter citizenship information.\n";
                    this.showInvalidDialog = true;
                }
                if (this.showDegreeCompletion && !this.degreeCompleted && !this.degreeCompletedDate) {
                    this.formErrorMessage += "Please enter the anticipated degree completion date\n";
                    this.showInvalidDialog = true;
                }
                if (!this.positionDesc && this.requireAddInfo) {
                    this.formErrorMessage +=  "Please provide additional position information.\n";
                    this.showInvalidDialog = true;
                }

                if (!this.showInvalidDialog && this.caseAction == "Reappointment") {
                    var reappCheck = this.$store.getters['sasData/reappointmentAllowed']({
                        title: this.title,
                        startTerm: this.startTerm,
                        adminOrg: this.adminOrg
                    });
                    var gracePeriod = "";
                    if (this.partTime.includes(this.title)) {
                        gracePeriod = "two year";
                    } else {
                        gracePeriod = "one term";
                    }
                    if (!reappCheck) {
                        this.reappErrorMessage =  "Our records indicate that this candidate is not eligible for reappointment because they have greater than a " + gracePeriod + " gap in service. If you believe there is an error in the records, you may override the reappointment validation by clicking below.";
                        this.showReappCheckDialog = true;
                    } else {
                        this.overrideReapp = false;
                        this.saveStep();
                        this.$emit('setDone', 'second,' + this.nextStep);
                    }
                } else if (!this.showInvalidDialog){
                    // this is reset here in case user originally overrode reapp and then went back and changed to appointment
                    this.overrideReapp = false;
                    this.saveStep();
                    this.$emit('setDone', 'second,' + this.nextStep);
                }
            },
            titleUpdated: function() {
                this.updateVisa();
                if(!this.showCitizenship) {
                    this.citizenship = null;
                }
                if(!this.showDegreeCompletion) {
                    this.degreeCompleted = true;
                    this.degreeCompletedDate = "";
                }
            },
            saveStep: function() {
                if (!this.division){
                    this.division = this.$store.getters['sasData/getUnitDivisions'](this.adminOrg)[0];
                }
                if (this.lpsUser === "lpsOnly") {
                    this.lps = true;
                }
                if (this.showReviewCmteSelect) {
                    var committees = [];
                    if (this.includeAssist) {
                        committees.push('ASST');
                    }
                    if (this.includeAssoc) {
                        committees.push('ASSO');
                    }
                    if (this.includeFull) {
                        committees.push('PROF');
                    }
                    this.facultyCommitteeRankCodes = committees.join();
                } else {
                    this.facultyCommitteeRankCodes = '';
                }
                // this is here in case the user entered funding and then went back
                // and selected a title that does not allow funding
                if (this.hideFunding) {
                    this.$store.dispatch('form/updateStep', {
                        step: 'fundingStep',
                        proposedSal: "",
                        fundingSource: "",
                        fundingNotes: "",
                        approvedBudgetAmt: "",
                        budgetFiscalYear: "",
                        budgetFundCode: "",
                        cref: "",
                        savePoint: 'second'
                    });
                }
                // this is here in case the user entered courses and then went back
                // and selected a title that does not allow courses
                if (this.hideCourses) {
                    this.$store.dispatch('form/updateStep', {
                        step: 'coursesStep',
                        allCourseNotes: "",
                        courses: [],
                        savePoint: 'second'
                    });
                }
                this.$store.dispatch('form/updateStep', {
                    step: 'positionStep',
                    adminOrg: this.adminOrg,
                    division: this.division,
                    startTerm: this.startTerm,
                    endTerm: this.endTerm,
                    caseAction: this.caseAction,
                    title: this.title,
                    positionDesc: this.positionDesc,
                    lps: this.lps,
                    langInstructor: this.langInstructor,
                    hasFunding: this.hasFunding,
                    hasCourses: this.hasCourses,
                    visaRequired: this.visaRequired,
                    visaType: this.visaType,
                    rankStartDate: !this.rankStartDate ? "" : this.rankStartDate.toString(),
                    startDate: !this.startDate ? "" : this.startDate.toString(),
                    endDate: !this.endDate ? "" : this.endDate.toString(),
                    degreeCompletedDate: this.degreeCompleted ? "" : !this.degreeCompletedDate ? "" : this.degreeCompletedDate.toString(),
                    facultyCommitteeRankCodes: this.facultyCommitteeRankCodes,
                    overrideReapp: this.overrideReapp,
                    savePoint: this.nextStep,
                    degreeCompleted: this.degreeCompleted,
                    citizenship: this.citizenship
                });
                if (this.adminOrg) {
                    this.$root.$emit('updateSubject');
                }
                if (this.startTerm) {
                    this.$root.$emit('updateTerm');
                }
                this.$root.$emit('updateFundingSource', this.lps);
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #general-info-form {
        text-align: left;
        height: 100%
    }

    #top-info {
        text-align: center;
    }

    .md-menu-content.md-select-menu {
        width: auto;
        max-width: none;
        min-width: 280px;
    }
</style>
