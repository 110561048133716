<template>
    <div class="about">
        <form class="md-layout md-alignment-center-center">
            <md-steppers class="md-layout-item md-size-75" :md-active-step.sync="active" md-linear md-dynamic-height>
                <md-step id="first" md-label="Identify Candidate" :md-done.sync="first">
                    <IdentifyCandidate v-on:setDone="setDone" v-bind:presetCase="presetCase" v-bind:initFromStore="initFromStore" v-bind:returnToConfirm="returnToConfirm"/>
                </md-step>
                <md-step id="second" md-label="Position" :md-done.sync="second">
                    <GeneralInfo v-on:setDone="setDone" v-bind:allTerms="getTermOptions" v-bind:initFromStore="initFromStore" v-bind:lpsUser="lpsUser"  v-bind:returnToConfirm="returnToConfirm"/>
                </md-step>
                <md-step id="third" md-label="Salary and Funding" :md-done.sync="third" :md-editable="showFunding">
                    <Funding v-on:setDone="setDone" v-bind:fundingSources="fundingSources" v-bind:initFromStore="initFromStore" v-bind:allYears="getYearOptions(true)"  v-bind:returnToConfirm="returnToConfirm"/>
                </md-step>
                <md-step id="fourth" md-label="Proposed Courses" :md-done.sync="fourth" :md-editable="showCourses">
                    <Course v-bind:allTerms="getTermOptions"  v-on:setDone="setDone"  v-bind:initFromStore="initFromStore" v-bind:returnToConfirm="returnToConfirm" />
                </md-step>
                <md-step id="fifth" md-label="Confirm" :md-done.sync="fifth">
                    <Confirmation v-bind:allTerms="getTermOptions" v-on:clearSavedCase="clearSavedCase" v-bind:fundingSources="fundingSources" v-bind:lpsUser="lpsUser" v-on:setIncomplete="setIncomplete"/>
                </md-step>
            </md-steppers>
        </form>
    </div>
</template>

<script>
    import GeneralInfo from '@/components/GeneralInfo.vue'
    import IdentifyCandidate from '@/components/IdentifyCandidate.vue'
    import Funding from '@/components/Funding.vue'
    import Course from '@/components/Course.vue'
    import Confirmation from '@/components/Confirmation.vue'
    import {FUNDING_SOURCE_TYPES} from "../store/store-utils";

    export default {
        name: 'home',
        components: {
            GeneralInfo,
            IdentifyCandidate,
            Funding,
            Course,
            Confirmation
        },
        data: () => ({
            active: 'first',
            first: false,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            presetCase: 0,
            initFromStore: false,
            returnToConfirm: false
        }),
        created() {
            if(this.$route.query.presetCase) {
                this.presetCase = this.$route.query.presetCase;
            }
            if(this.$route.query.initFromStore) {
              this.initFromStore = true;
            }
            this.$store.dispatch('form/loadForm');
            if (this.$store.getters['form/savePoint'] && this.$store.getters['form/savePoint'] != 'first') {
                let pages = ['first', 'second', 'third', 'fourth', 'fifth'];
                let savedPage = this.$store.getters['form/savePoint'];
                for (var p = 1; p < pages.length; p++) {
                    if (savedPage == pages[p]) {
                        this.active = savedPage;
                        break;
                    } else {
                        this[pages[p]] = true;
                    }
                }
            }
        },
        methods: {
            setDone: function (params) {
                this.$store.dispatch('form/saveForm');
                this.$emit('updateNav');
                var paramList = params.split(",");
                var id = paramList[0];
                var index = paramList[1];
                this[id] = true;
                if (this.returnToConfirm) {
                    this.active = 'fifth';
                } else if (index) {
                    this.active = index;
                }
            },
            setIncomplete: function (step) {
                this.active = step;
                this.returnToConfirm = true;
            },
            getYearOptions: function(forBudget = false) {
                var current_year = parseInt(new Date().getFullYear());
                if (forBudget && this.positionData.startTerm) {
                    current_year = parseInt(this.positionData.startTerm.slice(0, 4));
                }
                var allYears = [];
                for (var i = current_year - 1; i < current_year + 7; i++) {
                    if (forBudget) {
                        allYears.push(i + "-" + (i + 1));
                    } else {
                        allYears.push(i);
                    }
                }
                return allYears;
            },
            matchFullTitle: function(titleItem) {
                if (titleItem.title == this.positionData.title && !titleItem.specialCase) return true;
                if (titleItem.title + " - " + titleItem.specialCase == this.positionData.title) return true;
            },
            clearSavedCase: function () {
                this.$emit('clearSavedCase');
            }
        },
        computed: {
            getTermOptions: function() {
                var allYears = this.getYearOptions();
                var terms = {
                    "_SPRING": " Spring",
                    "_SUMMER_11_WEEK": " Summer 11 Week",
                    "_SUMMER_1": " Summer 1",
                    "_SUMMER_2": " Summer 2",
                    "_FALL": " Fall"
                };
                var allTerms = {};
                for (var year in allYears) {
                    var yearStr = allYears[year];
                    for(var termKey in terms) {
                        allTerms[yearStr + termKey] = yearStr + terms[termKey];
                    }
                }
                return allTerms;
            },
            lpsUser() {
                return this.$store.getters['user/appointmentPermission'];
            },
            formData() {
                return this.$store.getters['form/formData'];
            },
            positionData() {
                return this.formData['positionStep'];
            },
            titleActions() {
                return this.$store.getters['title/titleActions']
            },
            showFunding() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return !selectedTitle.hideFunding;
                }
                return true;
            },
            showCourses() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return !selectedTitle.hideCourses;
                }
                return true;
            },
            fundingSources() {
                var options = {};

                if (this.lpsUser != 'nonLps') {
                    options['lps'] = FUNDING_SOURCE_TYPES.lps;
                }

                if (!this.positionData.lps) {
                    options = {...FUNDING_SOURCE_TYPES};
                    delete(options.lps);
                }
                return options;
            }
        }
    }
</script>

<style>
    .spacer:after {
        height: 0px;
    }
    .spacer {
        min-height: 0px;
    }

    .spacer-no-bottom-margin {
        margin-bottom: 0px;
    }
</style>
