<template>
    <div id="identify-candidate">
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <p>
                    Please fill out <strong>one</strong> of the options below to complete this step.
                </p>
            </div>
            <div class="md-layout-item md-size-100">
                <div class="clickable-toolbar" @click="switchOption('option1')">
                    <md-toolbar :class="getOptionClass('option1')" md-elevation="0">
                        <div class="md-toolbar-section-start">
                            <md-button class="md-icon-button" @click="switchOption('option1')">
                                <md-icon>{{ getOptionIcon('option1') }}</md-icon>
                            </md-button>
                            <h2 class="md-title" id="selectInterfolioCase">Option 1: Select Candidate from Interfolio Search</h2>
                        </div>
                    </md-toolbar>
                </div>
                <div v-if="selectedOption == 'option1'">
                    <md-table v-model="kickableCases" @md-selected="selectCase" :md-selected-value.sync="selected">
                        <md-table-empty-state v-if="!casesLoaded" md-label="Loading" :md-description="`Loading Case Data from Interfolio`">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </md-table-empty-state>

                        <md-table-empty-state v-else md-label="No Cases found" :md-description="`You do not currently have any cases in your queue. Please use Option #2 or #3.`">
                        </md-table-empty-state>

                        <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single" class="md-primary" >
                            <md-table-cell md-label="Name" md-sort-by="lastname">{{ item.firstname }} {{ item.lastname }}</md-table-cell>
                            <md-table-cell md-label="Department/Unit" md-sort-by="unit_name">{{ item.unit_name }}</md-table-cell>
                        </md-table-row>
                    </md-table>
                    <md-button class="md-raised md-primary" @click="validate" :disabled="noCases"><span v-if="returnToConfirm">Return to Confirmation</span><span v-else>Select This Case</span></md-button>
                    <md-button class="md-raised md-primary" @click="clearSelected" :disabled="noCases">Clear</md-button>
                </div>
                <md-divider></md-divider>
                <div class="clickable-toolbar" @click="switchOption('option2')">
                    <md-toolbar :class="getOptionClass('option2')" md-elevation="0">
                        <div class="md-toolbar-section-start">
                            <md-button class="md-icon-button" @click="switchOption('option2')">
                                <md-icon>{{ getOptionIcon('option2') }}</md-icon>
                            </md-button>
                            <h2 class="md-title" id="selectPennPerson">Option 2: Select Candidate with Pennkey</h2>
                        </div>
                    </md-toolbar>
                </div>
                <div v-if="selectedOption == 'option2'">
                    <md-autocomplete v-model="candidate" :md-options="people" @md-changed="searchQueue" @md-selected="displaySelected" @md-opened="clearSelected">
                        <label>Select Candidate</label>
                        <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.desc }}</template>
                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                            <div v-if='lastSearch != ""' class="dialog-spinner" >
                                <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                            </div>
                            <div v-else-if="term">No person matching "{{ term }}" was found. Be sure to type at least 3 characters.</div>
                            <div v-else>Enter candidate Pennkey to search for Penn person</div>
                        </template>
                        <div class="md-helper-text">
                            Begin typing a Pennkey, PennID, or first and last name...
                        </div>
                    </md-autocomplete>
                    <p v-if="candidateDesc" class="md-body-2">
                        <strong>Selected User: {{ candidateDesc }}</strong>
                    </p>
                    <md-button class="md-raised md-primary" @click="validate"><span v-if="returnToConfirm">Return to Confirmation</span><span v-else>Select This User</span></md-button>
                    <md-button class="md-raised md-primary" @click="clearSelected">Clear</md-button>
                </div>
                <md-divider></md-divider>
                <div class="clickable-toolbar" @click="switchOption('option3')">
                    <md-toolbar :class="getOptionClass('option3')" md-elevation="0">
                        <div class="md-toolbar-section-start">
                            <md-button class="md-icon-button" @click="switchOption('option3')">
                                <md-icon>{{ getOptionIcon('option3') }}</md-icon>
                            </md-button>
                            <h2 class="md-title" id="addNew">Option 3: Create a New Candidate</h2>
                        </div>
                    </md-toolbar>
                </div>
                <div v-if="selectedOption == 'option3'">
                    <div class="md-layout md-alignment-top-space-between">
                        <div class="md-layout-item md-size-100">
                            <p>
                                If you can not find the candidate or they do not yet have a Pennkey, please provide their name and email.
                            </p>
                        </div>
                        <div class="md-layout-item md-size-45">
                            <md-field>
                                <label>First Name</label>
                                <md-input v-model="candidateFirstName" required></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-45">
                            <md-field>
                                <label>Last Name</label>
                                <md-input v-model="candidateLastName" required></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-45">
                            <md-field>
                                <label>Pennkey</label>
                                <md-input v-model="candidatePennkey"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-45">
                            <md-field>
                                <label>PennID</label>
                                <md-input v-model="candidatePennId"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout md-alignment-top-left">
                        <div class="md-layout-item md-size-45">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="candidateEmail" required></md-input>
                            </md-field>
                        </div>
                    </div>
                    <md-button class="md-raised md-primary" @click="validate"><span v-if="returnToConfirm">Return to Confirmation</span><span v-else>Continue</span></md-button>
                    <md-button class="md-raised md-primary" @click="clearSelected">Clear</md-button>

                </div>
            </div>
        </div>
        <FormInvalidWarning v-bind:showInvalidDialog='showInvalidDialog' v-bind:formErrorMessage='formErrorMessage' v-on:closeWarning='closeAndOpenThree'/>
        <div id="pcom-check">
            <md-dialog :md-active.sync="showPcomCheckDialog">
                <md-dialog-title>Validating Candidate Data:</md-dialog-title>
                <div class="dialog-content">
                    <div v-if="candidateCheckLoading" class="dialog-spinner">
                        <md-progress-spinner md-mode="indeterminate" v-if="candidateCheckLoading"></md-progress-spinner>
                        <md-field class="md-layout-item md-size-100 spacer spacer-no-bottom-margin"></md-field>
                    </div>
                    <div v-else>
                        <p>
                            You entered the following without linked Penn info: {{ candidateFirstName }} {{ candidateLastName }}
                        </p>
                      <md-button class="md-accent md-raised" @click="createNewCandidate">I am certain candidate does not have Penn record</md-button>
                      <md-divider></md-divider>
                      Searching for Matches:

                      <h3 v-if="kickableCasesCheckResults.length > 0">Searching Interfolio:</h3>
                        <md-list v-if="kickableCasesCheckResults.length > 0">
                            <md-list-item v-for='caseResult in kickableCasesCheckResults' v-bind:key='caseResult.id' style="justify-content: flex-start">
                                <md-button @click="updateCase(caseResult)" class="md-raised md-primary">pick</md-button>
                                <a href="#" @click="updateCase(caseResult)">{{ caseResult.firstname }} {{ caseResult.lastname }} - {{ caseResult.email }}</a>
                            </md-list-item>
                        </md-list>
                        <md-divider v-if="kickableCasesCheckResults.length > 0"></md-divider>
                        <h3 v-if="pcomCheckResults.length > 0">Searching Penn:</h3>
                        <md-list>
                            <md-list-item v-for='pcomResult in pcomCheckResults' v-bind:key='pcomResult.penn_id'>
                              <md-button @click="updateCandidate(pcomResult)" class="md-raised md-primary">pick</md-button>
                              <a href="#" @click="updateCandidate(pcomResult)">{{ pcomResult.desc }}</a>
                            </md-list-item>
                        </md-list>
                        <md-dialog-actions>
                          <md-button class="md-raised" @click="showPcomCheckDialog = false">Cancel</md-button>
                        </md-dialog-actions>
                    </div>
                </div>
            </md-dialog>
        </div>
        <div id="option-select">
            <md-dialog :md-active.sync="showOptionSelectDialog">
                <md-dialog-title>Please select the option that best describes this candidate:</md-dialog-title>
                <md-list>
                    <md-list-item v-if="!noCases">
                        <md-avatar>
                            <img src="../../public/interfolio_icon.png" alt="Avatar">
                        </md-avatar>
                        <span class="md-list-item-text"><a href="#" @click="selectOption('option1')">Candidate was identified through Interfolio Search</a></span>
                    </md-list-item>
                    <md-divider v-if="!noCases"></md-divider>
                    <md-list-item>
                        <md-avatar>
                            <img src="../../public/penn_icon.png" alt="Avatar">
                        </md-avatar>
                        <span class="md-list-item-text"><a href="#" @click="selectOption('option2')">Candidate is not in Interfolio Search, but has a Pennkey</a></span>
                    </md-list-item>
                    <md-divider></md-divider>
                    <md-list-item>
                        <md-avatar>
                            <img src="../../public/new_icon.png" alt="Avatar">
                        </md-avatar>
                        <span class="md-list-item-text"><a href="#" @click="selectOption('option3')">Candidate is not in Interfolio Search and does not have a Pennkey</a></span>
                    </md-list-item>
                </md-list>
            </md-dialog>
        </div>
    </div>
</template>

<script>
    import {pcomSearch} from "../graphql/queries";
    import API from '@aws-amplify/api';
    import { graphqlOperation } from '@aws-amplify/api';
    import debounce from 'debounce'
    import FormInvalidWarning from '@/components/FormInvalidWarning.vue'
    import {LOAD_STATUS } from "../store/store-utils"

    async function searchPcom(searchTerm, authenticated, store) {
        if(authenticated) {
            try {
                let matchedPeople = [];
                //uri encode everything except spaces
                let terms = encodeURI(searchTerm.replace(' ', 'AAAAAAAA').replace(/[‘’]/g, "'"));
                //replace separate terms with separate term values
                terms = "term=" + terms.replace("AAAAAAAA", "&term=");

                let res = await API.graphql(graphqlOperation(pcomSearch, {"searchTerm": terms}));
                for (const matchedPerson of res.data.pcomSearch) {
                    matchedPeople.push({
                        "penn_id": matchedPerson.penn_id,
                        "desc": matchedPerson.description,
                        "pennkey": matchedPerson.kerberos_principal,
                        "first_name": matchedPerson.first_name,
                        "middle_name": matchedPerson.middle_name,
                        "last_name": matchedPerson.last_name,
                        "email": matchedPerson.email
                    });
                }
                return matchedPeople;
            } catch (e) {
                store.dispatch('error/addError', "Problem connecting to Penn Community.");
            }
        }
    }

    export default {
        name: 'IdentifyCandidate',
        components: {
            FormInvalidWarning
        },
        data: function() {
            return {
                interfolioCase: this.$store.getters['form/stepData']('candidateStep')['interfolioCase'] || null,  // if user selected an interfolio case, value will be here
                candidate: this.$store.getters['form/stepData']('candidateStep')['candidate'] || null,  // if user used people picker, value will be here
                // if user didn't user either of two above, at least first name, last name, and email will have values
                candidateFirstName: this.$store.getters['form/stepData']('candidateStep')['candidateFirstName'] || "",
                candidateLastName: this.$store.getters['form/stepData']('candidateStep')['candidateLastName'] || "",
                candidatePennkey: this.$store.getters['form/stepData']('candidateStep')['candidatePennkey'] || "",
                candidatePennId: this.$store.getters['form/stepData']('candidateStep')['candidatePennId'] || "",
                candidateEmail: this.$store.getters['form/stepData']('candidateStep')['candidateEmail'] || "",
                candidateDesc: "",
                people: [],  // options for people picker
                pcomCheckResults: [], // options for validating manual input
                kickableCasesCheckResults: [], // options for validating manual input
                showInvalidDialog: false,
                showPcomCheckDialog: false,
                showOptionSelectDialog: false,
                formErrorMessage: "",
                candidateCheckLoading: false,
                selected: [], // had to add this so I can clear selections manually
                selectedOption: "option1",
                lastSearch: "" // added this to prevent a slower search result overwriting a more recent, faster search result
            }
        },
        props: {
            presetCase: Number,
            initFromStore: Boolean,
            returnToConfirm: Boolean
        },
        created () {
            this.searchQueue = debounce(this.searchQueue, 200);
            if (this.initFromStore) {
                let storeData = this.$store.getters['form/stepData']('candidateStep');
                for(let field in storeData) {
                    if(field !== 'step') {
                        this[field] = storeData[field];
                    }
              }
              this.selectedOption = "option3";
              //this.$emit('setDone', 'first');
            }
        },
        mounted () {
            if (this.presetCase != 0) {
                for(var i = 0; i < this.kickableCases.length; i++) {
                    if (this.kickableCases[i].id == this.presetCase) {
                        this.selected = this.kickableCases[i];
                        this.selectCase(this.kickableCases[i]);
                    }
                }
            } else if (localStorage.getItem("caseKickoffForm")) {
                if (this.interfolioCase) {
                    for(var j = 0; j < this.kickableCases.length; j++) {
                        if (this.kickableCases[j].id == this.interfolioCase.id) {
                            this.selected = this.kickableCases[j];
                            this.selectCase(this.kickableCases[j]);
                        }
                    }
                    this.selectedOption = "option1";
                } else if (this.candidate) {
                    this.selectedOption = "option2";
                    this.candidateDesc = this.candidateFirstName + " " + this.candidateLastName + " (" + this.candidatePennkey + ", " + this.candidatePennId + ") - " + this.candidateEmail;
                } else {
                    this.selectedOption = "option3";
                }
            } else if (!this.initFromStore) {
                this.showOptionSelectDialog = true;
                if (this.noCases) {
                    this.selectedOption = "option2";
                }
            }
        },
        computed: {
            kickableCases() {
                return this.$store.getters['interfolio/kickoffCases'];
            },
            casesLoaded() {
                return this.$store.getters['interfolio/caseLoadStatus'] === LOAD_STATUS.LOADED;
            },
            noCases() {
                if (this.kickableCases && this.kickableCases.length > 0) return false;
                return true;
            }
        },
        methods: {
            getOptionClass: function (option) {
                if (this.selectedOption == option) {
                    return 'md-primary';
                } else {
                    return 'md-transparent';
                }
            },
            getOptionIcon: function (option) {
                if (this.selectedOption == option) {
                    return 'keyboard_arrow_up';
                } else {
                    return 'keyboard_arrow_down';
                }
            },
            switchOption: function (option) {
                this.selectedOption = option;
            },
            selectOption: function (option) {
                this.switchOption(option);
                this.showOptionSelectDialog = false;
            },
            searchQueue: function(searchTerm) {
                this.lastSearch = searchTerm;
                this.pcomSearch(searchTerm).then(result => {
                    if (this.lastSearch == searchTerm) {
                        this.people = result;
                        this.lastSearch = "";
                    }
                });
            },
            pcomSearch: function (searchTerm) {
                if (searchTerm.length > 2) {
                    return new Promise(resolve => {
                        window.setTimeout(() => {
                            if (searchTerm) {
                                const term = searchTerm.toLowerCase()
                                let result = searchPcom(term, this.$store.getters['user/authenticated'], this.$store);
                                resolve (result);
                            }
                        }, 200)
                    });
                } else if (searchTerm.length == 0) {
                    this.clearSelected();
                }
            },
            displaySelected: function (value) {
                this.candidateFirstName = value.first_name;
                this.candidateLastName = value.last_name;
                if (value.middle_name) {
                  this.candidateFirstName += " " + value.middle_name;
                }
                if(value.pennkey) {
                  this.candidate = value.pennkey;
                }
                if(value.penn_id) {
                  this.candidatePennId = value.penn_id;
                }
                if(value.email) {
                  this.candidateEmail = value.email;
                }
                this.candidateDesc = value.desc;
            },
            clearSelected: function () {
                this.candidate = null;
                this.interfolioCase = null;
                this.selected = [];
                this.people = [];
                this.candidateFirstName = "";
                this.candidateLastName = "";
                this.candidatePennkey = "";
                this.candidatePennId = "";
                this.candidateEmail = "";
                this.candidateDesc = "";
            },
            validate: async function () {
                // check that at least name and email are filled out
                if (!this.candidateFirstName) {
                    this.formErrorMessage = "Candidate first name is required. If you can not find the candidate in Interfolio or the Penn Person search, please complete the information in Option 3.";
                    this.showInvalidDialog = true;
                } else if (!this.candidateLastName) {
                    this.formErrorMessage = "Candidate last name is required. If you can not find the candidate in Interfolio or the Penn Person search, please complete the information in Option 3.";
                    this.showInvalidDialog = true;
                } else if (!this.candidateEmail) {
                    this.formErrorMessage = "Candidate email is required. If you can not find the candidate in Interfolio or the Penn Person search, please complete the information in Option 3.";
                    this.showInvalidDialog = true;
                } else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(this.candidateEmail))) {
                    this.formErrorMessage = "Please enter a valid email address. If you can not find the candidate in Interfolio or the Penn Person search, please complete the information in Option 3.";
                    this.showInvalidDialog = true;
                } else if (this.interfolioCase){
                    this.pcomCheckResults = []
                    this.candidateCheckLoading = true;
                    let personCheckTerm = this.candidateLastName + " " + this.candidateFirstName;
                    this.showPcomCheckDialog = true;
                    this.kickableCasesCheckResults = []; //need to clear it in case user searches more than once
                    let results = await this.checkPcom(personCheckTerm);
                    this.candidateCheckLoading = false;
                    if (results.length != 0 || this.kickableCasesCheckResults.length != 0) {
                        this.pcomCheckResults = results;
                    } else {
                        // couldn't find anyone at Penn with matching name
                        this.showPcomCheckDialog = false;
                        this.saveStep();
                        this.$emit('setDone', 'first,second');
                    }
                } else if ((!this.interfolioCase || this.interfolioCase == "") && !this.candidate) {
                    this.pcomCheckResults = []
                    this.candidateCheckLoading = true;
                    let personCheckTerm = this.candidateLastName + " " + this.candidateFirstName;
                    this.showPcomCheckDialog = true;
                    this.kickableCasesCheckResults = []; //need to clear it in case user searches more than once
                    let intCases = this.kickableCases;
                    for (let intCase of intCases) {
                        if (intCase.firstname && intCase.lastname && intCase.email && (
                            intCase.firstname.toLowerCase() == this.candidateFirstName.toLowerCase() ||
                            intCase.lastname.toLowerCase() == this.candidateLastName.toLowerCase() ||
                            intCase.email.toLowerCase() == this.candidateEmail.toLowerCase())){
                            this.kickableCasesCheckResults.push(intCase);
                        }
                    }
                    let results = await this.checkPcom(personCheckTerm);
                    this.candidateCheckLoading = false;
                    if (results.length != 0 || this.kickableCasesCheckResults.length != 0) {
                        this.pcomCheckResults = results;
                    } else {
                        // couldn't find anyone at Penn with matching name
                        this.showPcomCheckDialog = false;
                        this.saveStep();
                        this.$emit('setDone', 'first,second');
                    }
                } else {
                    this.saveStep();
                    this.$emit('setDone', 'first,second');
                }
            },
            checkPcom: function(checkTerm) {
                return new Promise(resolve => {
                    window.setTimeout(() => {
                        let result = searchPcom(checkTerm, this.$store.getters['user/authenticated']);
                        resolve (result);
                    }, 200)
                });
            },
            updateCandidate: function(candidate) {
                this.displaySelected(candidate);
                this.showPcomCheckDialog = false;
                if (!this.candidateEmail) {
                    this.formErrorMessage = "Candidate email is required. There is no email for this person in the Penn Directory, please provide the email in Option 3.";
                    this.showInvalidDialog = true;
                } else {
                    this.saveStep();
                    this.$emit('setDone', 'first,second');
                }
            },
            createNewCandidate: function() {
                this.showPcomCheckDialog = false;
                this.saveStep();
                this.$emit('setDone', 'first,second');
            },
            updateCase: function(intCase) {
                this.selected = intCase;
                this.selectCase(intCase);
                this.showPcomCheckDialog = false;
                if (!this.candidateEmail) {
                    this.formErrorMessage = "Candidate email is required. There is no email for this person in Interfolio Faculty Search, please provide the email in Option 3.";
                    this.showInvalidDialog = true;
                } else {
                    this.saveStep();
                    this.$emit('setDone', 'first,second');
                }
            },
            selectCase: function(item) {
                if (item.id){
                    this.interfolioCase = item;
                    this.candidateFirstName = item.firstname;
                    this.candidateLastName = item.lastname;
                    this.candidateEmail = item.email;
                    this.candidate = null;
                    this.candidatePennkey = "";
                    this.candidatePennId = "";
                }
            },
            saveStep: function() {
                this.$store.dispatch('form/updateStep', {
                    step: 'candidateStep',
                    interfolioCase: this.interfolioCase,  // if user selected an interfolio case, value will be here
                    candidate: this.candidate,  // if user used people picker, value will be here
                    // if user didn't user either of two above, at least first name, last name, and email will have values
                    candidateFirstName: this.candidateFirstName,
                    candidateLastName: this.candidateLastName,
                    candidatePennkey: this.candidatePennkey,
                    candidatePennId: this.candidatePennId,
                    candidateEmail: this.candidateEmail,
                    savePoint: 'second'
                });
            },
            closeAndOpenThree: function() {
                this.showInvalidDialog = false;
                this.switchOption('option3');
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #identify-candidate .md-table-head {
        text-align: center;
    }
    #identify-candidate .md-transparent {
        background-color: lightgray !important;
    }
    .clickable-toolbar {
        cursor: pointer;
    }
    .dialog-content {
        padding: 0 2rem;
    }
    .dialog-spinner {
        text-align: center;
    }
</style>
